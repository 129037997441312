// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

import '../application.scss';

import 'bootstrap';
import 'bootstrap-select';
import 'alpinejs';

document.addEventListener("turbolinks:load", () => {
  if (window.reviveAsync) {
    var key = Object.keys(reviveAsync)[0];
    reviveAsync[key].apply(reviveAsync[key].detect());
  }

  $('.toast').toast('show');

  $('form.needs-validation').on('submit', function (e) {
    if (!this.checkValidity()) {
      e.preventDefault();
      e.stopPropagation();

      $(this).addClass('was-validated');
    }
  });


  (() => {
    window.dataLayer = window.dataLayer || []
    function gtag() { dataLayer.push(arguments); }

    gtag('js', new Date());
    gtag('config', 'UA-147991247-2', {
      'cookie_flags': 'max-age=7200;secure;samesite=none'
    });
  })();
});

import "controllers"

import "./reviews.js";
