import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    $(this.scope.element).selectpicker({
      style: '',
      styleBase: 'form-control text-dark',
      noneResultsText: 'Nichts gefunden',
      selectAllText: 'Alle',
      deselectAllText: 'Keine',
      iconBase: 'fal',
      tickIcon: 'fa-check-circle fa-lg text-primary',
      countSelectedText (n) {
        return `${n} ausgewählt`;
      }
    });
  }
}
