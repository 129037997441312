window.ReviewForm = {
  rated: false,
  agreed: false,
  rate () {
    this.rated = true;
  },
  agree () {
    this.agreed = true;
    console.log('was');
  }
};


// $('#new_review input:radio').on('click', function () {
//   if ($('#new_review input:checkbox').prop('checked')) {
//     $('#new_review button:submit').prop('disabled', false);
//   }
// });

// $('#new_review input:checkbox').on('change', function () {
//   if ($('#new_review input:radio:checked').length > 0) {
//     $('#new_review button:submit').prop('disabled', false);
//   }
// });
